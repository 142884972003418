import axios from 'axios';
import { keycloakConnection } from 'components/iam'
import { trackPromise } from 'react-promise-tracker';

axios.interceptors.request.use(
  config => {
    const token = keycloakConnection.keycloak.token;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  const originalRequest = error.config;

  if (error.response.status === 401 && originalRequest._retry === true) {
    return Promise.reject(error);
  }

  return Promise.reject(error);
});

const orig_get = axios.get;
const spinner_get = (...args) => {
    return trackPromise(orig_get(...args));
}
axios.get = spinner_get;

const orig_post = axios.post;
const spinner_post = (...args) => {
    return trackPromise(orig_post(...args));
}
axios.post = spinner_post;

const orig_put = axios.put;
const spinner_put = (...args) => {
    return trackPromise(orig_put(...args));
}
axios.put = spinner_put;

const orig_delete = axios.delete;
const spinner_delete = (...args) => {
    return trackPromise(orig_delete(...args));
}
axios.delete = spinner_delete;

export default axios;
