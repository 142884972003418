import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {freeSet, flagSet, brandSet} from '@coreui/icons';
import { logo } from './assets/icons/logo'

import { Provider } from 'react-redux'
import store from './store'
import { keycloakConnection } from './components/iam'
import { KeycloakProvider } from './components/KeycloakProvider'

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();
  return (
   promiseInProgress &&
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(16, 16, 16, 0.5)',
        zIndex: 1000000,
        textAlign: 'center',
        paddingTop: '250px',
      }}
    >
      <Loader type="Oval" color="#3c4b64" height="50" width="50" />
    </div>
 );
}

React.icons = {...freeSet, ...flagSet, ...brandSet, logo}
if (window.location.href.includes('certificates/check')) {
    ReactDOM.render(
    <Provider store={store}>
      <LoadingIndicator/>
      <App/>
    </Provider>,
    document.getElementById('root')
    );
} else {
  keycloakConnection.init(window.location.href).then(authenticated => {
    ReactDOM.render(
    <Provider store={store}>
      <KeycloakProvider
          keycloakConnection={keycloakConnection}
          authenticated={authenticated}>
        <LoadingIndicator/>
        <App/>
      </KeycloakProvider>
    </Provider>,
    document.getElementById('root')
    );
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
