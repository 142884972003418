import { useAuthenticate } from '../hooks/useAuthenticate'
import { KeycloakConnection } from './iam'
import { useSelector, useDispatch } from 'react-redux'

export const KeycloakProvider = ({ children, keycloakConnection, authenticated }) => {
  const dispatch = useDispatch()
  keycloakConnection.setOnUpdateToken((token) => {
    if (token) dispatch({type: 'set', token: token})
  })
  const keycloak = keycloakConnection.getKeycloakInstance()
  useAuthenticate(keycloak, authenticated)
  return <>{children}</>
}
