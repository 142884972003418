import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'

export const useAuthenticate = (keycloak, authenticated) => {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch()

  useEffect(() => {
    if (!authenticated) keycloak.login()
    else if (!user || !user.email) {
      if (keycloak.token) dispatch({type: 'set', token: keycloak.token})
      keycloak
        .loadUserInfo()
        .then((userInfo) => {
          dispatch({type: 'set', user: userInfo})
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  useEffect(() => {
    dispatch({type: 'set', keycloak: keycloak})
  }, [keycloak])
}
