import Keycloak, { KeycloakInitOptions } from 'keycloak-js'

export class KeycloakConnection {

  constructor() {
    this.keycloak = new Keycloak('/keycloak.json')

    this.keycloak.onTokenExpired = () => {
      this.keycloak
        .updateToken(30)
        .then((success) => {
          if (success) this.onUpdateToken(this.keycloak.token)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  getInitConfig(href) {
    return !this.token
      ? {
          onLoad: 'login-required',
          redirectUri: href,
        }
      : {
          checkLoginIframe: false,
          ...this.token,
        }
  }

  getKeycloakInstance() {
    return this.keycloak
  }

  setOnUpdateToken(onUpdateToken) {
    this.onUpdateToken = onUpdateToken
  }

  setToken(accessToken, refreshToken) {
    this.token = { token: accessToken, refreshToken: refreshToken }
  }

  init(href) {
    return new Promise((resolve, reject) => {
      this.keycloak
        .init(this.getInitConfig(href))
        .then((authenticated) => {
          if (!authenticated && this.token) {
            this.token = undefined

            this.init(href)
              .then((authenticated) => {
                resolve(authenticated)
              })
              .catch((error) => {
                reject(error)
              })
          } else {
            resolve(authenticated)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const keycloakConnection = new KeycloakConnection()

export { keycloakConnection }
