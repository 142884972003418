import { useSelector, useDispatch } from 'react-redux'
import React, { useState, useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './scss/style.scss';
import useLocalStorage from 'components/useLocalStorage'
import connection from 'components/connection'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const PublicLayout = React.lazy(() => import('./containers/PublicLayout'));
const CustomerContext = React.createContext(null);
const UserContext = React.createContext(null);


const App = (props) => {
  const [global_customer, setGlobalCustomer] = useLocalStorage('customer', {});
  const user = useSelector(state => state.user);
  const authenticated = user != null;
  const [userInfo, setUserInfo] = useState(
    {
        name: "",
        email: "",
        role: "",
        location: "",
        master_template: "",
        id: "",
        can_add_certificates: false,
        kravag_online_url: "",
    }
  )

  useEffect(() => {
    if (authenticated) {
      connection.get('/api/users/data').then(
        res => {
          let data = res.data;
          if (!data.name) {
            data.name = data.email;
          }
          setUserInfo(data)
        }
      )
    }
  }, [authenticated])

  if (userInfo.email) { return (
    <UserContext.Provider value={userInfo}>
    <CustomerContext.Provider value={{'setGlobalCustomer': setGlobalCustomer,
                                      'global_customer': global_customer}}>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route path="/" name="Home" render={
              props => <DefaultLayout {...props}/>
            } />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </CustomerContext.Provider>
    </UserContext.Provider>
  ); } else { return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route path="/" name="Home" render={
              props => <PublicLayout {...props}/>
            } />
          </Switch>
        </React.Suspense>
      </HashRouter>

  ) }
}

export default App;
export { UserContext, CustomerContext };
